
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EmailEditorForm from '@/components/forms/settings/EmailEditorForm.vue';
import { Actions, Getters, Mutations } from '@/store/enums/TemplateEnums';
import toasts from '@/utils/toasts';
import { Template } from '@/models/TemplateModel';
import store from '@/store';

export default defineComponent({
  name: 'email-template-editor-page',
  components: {
    EmailEditorForm,
  },
  data: () => ({
    pageLoading: false,
    formSubmitted: false,
    templateType: '' as any,
    showForm: false,
  }),
  mounted() {
    this.pageLoading = true;

    this.setTitle();

    this.loadTemplate();
  },
  computed: {
    ...mapGetters({
      template: Getters.GET_TEMPLATE,
      title: Getters.GET_TITLE,
      errors: Getters.GET_ERROR,
      mailingList: Getters.GET_MAILING_LIST,
      placeholders: Getters.GET_PLACEHOLDERS,
    }),
    placeholderOptions() {
      let placeholderOptions = [] as any;

      if (typeof this.placeholders !== 'undefined') {
        this.placeholders.map((placeholder) => {
          placeholderOptions.push({
            value: placeholder,
            label: placeholder,
          });
        });
      }
      return placeholderOptions;
    },
  },
  methods: {
    ...mapActions({
      fetchTemplateByName: Actions.FETCH_TEMPLATE_BY_SYSTEM_NAME,
      updateTemplate: Actions.UPDATE_TEMPLATE,
      createTemplate: Actions.CREATE_TEMPLATE,
    }),

    setTitle() {
      const { template } = this.$route.query;

      const filteredList = this.mailingList.filter(
        (mailList: any | Template, key) => {
          return mailList?.system_name === template;
        }
      );

      store.commit(Mutations.SET_TITLE, filteredList[0]?.name);
    },

    async loadTemplate() {
      const { template } = this.$route.query;
      const templateType = this.$route.meta.template;

      this.templateType = template;

      if (typeof this.templateType === 'undefined') return;

      await this.fetchTemplateByName({
        templateType: templateType,
        systemName: template,
      })
        .then(() => {
          this.pageLoading = false;
        })
        .catch(() => {
          store.commit(Mutations.SET_TEMPLATE, {});
          this.pageLoading = false;
        })
        .finally(() => {
          this.showForm = true;
        });
    },

    handleUpdateTemplate(values) {
      if (this.formSubmitted) return;

      this.formSubmitted = true;

      const { template } = this.$route.query;
      const templateType = this.$route.meta.template;

      let formData = {
        ...values,
        ...{
          system_name: template,
        },
      };

      this.updateTemplate({
        templateType: templateType,
        id: values.id,
        payload: formData,
      })
        .then(() => {
          this.formSubmitted = false;
          toasts.success('Template Successfully Saved');
        })
        .catch(() => {
          this.formSubmitted = false;
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },
    handeCreateTemplate(values) {
      if (this.formSubmitted) return;

      const { template } = this.$route.query;
      const templateType = this.$route.meta.template;

      this.formSubmitted = true;

      let formData = {
        ...values,
        ...{
          system_name: template,
        },
      };

      this.createTemplate({
        templateType: templateType,
        payload: formData,
      })
        .then(() => {
          this.formSubmitted = false;
          toasts.success('Template Successfully Saved');
        })
        .catch(() => {
          this.formSubmitted = false;
          const { errors, message } = this.errors;

          toasts.error(errors, message);
        });
    },
  },
  watch: {
    $route: {
      handler(value) {
        this.showForm = false;
        this.pageLoading = true;

        if (typeof value.query.template !== 'undefined') {
          this.templateType = value.query.template;
          this.showForm = true;
          this.pageLoading = false;
        }
      },
      immediate: true,
    },
  },
});
